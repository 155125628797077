<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        class="print-left-column"
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <div
            v-show="currentUser.role_name !== 'customer'"
            class="showOnprint"
          >
            <b-card-body
              v-if="currentUser.role_name !== 'auctioneer'"
              class="invoice-padding pb-0"
            >
              <div
                class="print-info d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                  </div>
                  <h6 class="mb-2">
                    {{ $t('invoiceTo') }}
                  </h6>
                  <h6 class="mb-25">
                    {{ taxInfo.corporate_name }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ $t('address') }}: {{ taxAddress }}
                  </p>
                  <p class="card-text mb-25">
                    RFC: {{ taxInfo.rfc }}
                  </p>
                  <p class="card-text mb-0">
                    {{ $t('email') }}: {{ taxInfo.email }}
                  </p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    <div class="h5">
                      <span class="invoice-number">{{ project.name }}</span>
                    </div>
                    <div class="h5">
                      {{ $t('projectFolio') }}
                      <span class="invoice-number">#{{ project.folio }}</span>
                    </div>
                    <div class="h5">
                      {{ $t('quoteFolio') }}
                      <span class="invoice-number">#{{ quote.folio }}</span>
                    </div>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      {{ $t('dateIssued') }}
                    </p>
                    <p class="invoice-date">
                      {{ quote.published_at | date }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <div
              v-if="currentUser.role_name === 'auctioneer'"
              class="logo-wrapper mb-0"
            >
              <logo />
            </div>

            <!-- Spacer -->
            <hr
              v-if="currentUser.role_name !== 'auctioneer'"
              class="invoice-spacing"
            >

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0"
                >
                  <h6 class="mb-1">
                    {{ $t('shipmentTo') }}
                  </h6>
                  <p
                    v-if="project.google_maps_url"
                    class="card-text mb-25"
                  >
                    <a
                      :href="project.google_maps_url"
                      target="_blank"
                    >
                      {{ $t('Google Maps') }}
                    </a>
                  </p>
                  <p class="card-text mb-25">
                    {{ $t('address') }}: {{ address.formatted_address }}
                  </p>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">
                      {{ $t('paymentDetails') }}
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            {{ $t('total') }}:
                          </td>
                          <td>
                            <span
                              class="font-weight-bold"
                            >${{ quote.total | money }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </div>

          <!-- Invoice Description: Table -->
          <invoice-products-table
            :products="items"
            @delete-user="refetch"
          />
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              />

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end mb-2"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('subtotal') }}:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ quote.subtotal | money }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('shipping') }}:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ quote.shipping_total | money }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('tax') }}:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ quote.iva_total | money }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('total') }}:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ quote.total | money }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <component
          :is="optionsBasedInRole"
          :quote="quote"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="!['auctioneer', 'supplier_admin'].includes(currentUser.role_name)"
      class="mt-2"
    >
      <b-col
        sm="12"
        class="print-left-column"
      >
        <quote-cost-resume :quote-items="items" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Logo from '@/components/Logo.vue'
import InvoiceProductsTable from '@/components/invoice/InvoiceProductsTable.vue'
import CheckoutOptionsForAdmin from '@/components/invoice/CheckoutOptionsForAdmin.vue'
import CheckoutOptionsForUser from '@/components/invoice/CheckoutOptionsForUser.vue'
import PurchaseOrderOptionsForAuctioneer from '@/components/purchaseOrders/PurchaseOrderOptionsForAuctioneer.vue'
import PurchaseOrderOptionsForAdmin from '@/components/purchaseOrders/PurchaseOrderOptionsForAdmin.vue'
import router from '@/router'
import QuoteCostResume from '@/components/pricing/QuoteCostResumeTable.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    Logo,
    InvoiceProductsTable,
    CheckoutOptionsForAdmin,
    CheckoutOptionsForUser,
    PurchaseOrderOptionsForAdmin,
    PurchaseOrderOptionsForAuctioneer,
    QuoteCostResume,
  },

  props: {
    quote: {
      type: Object,
      default() {
        return {}
      },
    },
    project: {
      type: Object,
      default() {
        return {}
      },
    },
    address: {
      type: Object,
      default() {
        return {}
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    taxInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    refetch: {
      type: Function,
      default() {
        return 0
      },
    },
  },
  setup(props, { root }) {
    const store = root.$store
    const paymentDetails = ref({})

    // Invoice Description
    // ? Your real data will contain this information
    const printInvoice = () => {
      window.print()
    }

    const supplierRoles = ['suppler_admin', 'auctioneer']

    const currentUser = computed(() => store.state.auth.currentUser)

    function isValid(string) {
      if (typeof string === 'string') return string
      return ''
    }

    const taxAddress = computed(
      () => `${isValid(props.taxInfo.street)}, ${isValid(
        props.taxInfo.ext_number,
      )},
        ${isValid(props.taxInfo.postal_code)}, ${isValid(props.taxInfo.city)},
        ${isValid(props.taxInfo.state)}`,
    )

    const optionsBasedInRole = computed(() => {
      // Options for purchase order view
      if (router.currentRoute.name === 'purchaseOrder') {
        if (currentUser.value.role_name === 'admin') {
          return 'PurchaseOrderOptionsForAdmin'
        }
        if (currentUser.value.role_name === 'auctioneer') {
          return 'PurchaseOrderOptionsForAuctioneer'
        }
      }
      // Options for quote view
      if (currentUser.value.role_name === 'admin') {
        return 'CheckoutOptionsForAdmin'
      }
      if (supplierRoles.includes(currentUser.value.role_name)) {
        return 'CheckoutOptionsForSupplier'
      }
      return 'CheckoutOptionsForUser'
    })

    return {
      paymentDetails,
      printInvoice,
      optionsBasedInRole,
      taxAddress,
      currentUser,
    }
  },

  computed: {
    subtotal() {
      return this.items.reduce((prev, next) => ({
        total: parseFloat(prev.total) + parseFloat(next.total),
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss">
@media print {
  // Global Styles
  body,
  text,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    background: transparent !important;
    color: #000 !important;
  }

  .print-info{
    display: flex;
    flex-direction: row !important;
  }

  .showOnprint {
    display: block !important;
  }
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  nav.header-navbar {
    display: none;
  }
  .print-left-column {
    width: 100% !important;
    max-width: 100%;
    flex-grow: 1;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }

  .noprint {
    display: none;
  }
}
</style>
