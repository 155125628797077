<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      responsive
      :items="payments"
      :fields="fields"
    >
      <template #cell(name)="data">
        {{ $t('file') }} {{ data.index + 1 }}
      </template>
      <template #cell(created_at)="data">
        {{ data.value | date }}
      </template>
      <template #cell(amount)="data">
        $ {{ data.value | money }}
      </template>
      <template #cell(is_invoiceable)="data">
        <div class="d-flex align-items-center">
          <b-badge
            :variant="data.value ? 'success' : 'secondary'"
            class="w-100"
          >
            {{ data.value ? $t('invoiced') : $t('pending') }}
          </b-badge>
        </div>
      </template>
      <template #cell(payment_attachment)="data">
        <b-btn
          class="mr-2"
          size="sm"
          variant="outline-secondary"
          :href="data.value"
          target="_blank"
        >
          <feather-icon
            size="12"
            icon="DownloadIcon"
          />
        </b-btn>
        <b-btn
          v-b-modal.confirmDelete
          size="sm"
          variant="outline-danger"
          target="_blank"
          @click="paymentToDeleted = data.item"
        >
          <feather-icon
            size="12"
            icon="TrashIcon"
          />
        </b-btn>
      </template>
    </b-table>
    <confirmation-modal
      :id="`confirmDelete`"
      :variant="'danger'"
      @confirm="removepayment()"
    >
      <template v-slot:content>
        {{
          $t('deletePaymentConfirmation', { amount: paymentToDeleted.amount })
        }}
      </template>
    </confirmation-modal>
  </b-card>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: {
    BTable,
    ConfirmationModal,
  },
  props: {
    payments: {
      type: Array,
      default() {
        return []
      },
    },
    noTools: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('file'),
        },
        // {
        //   key: 'created_at',
        //   label: this.$t('createdAt'),
        // },
        // {
        //   key: 'amount',
        //   label: this.$t('amount'),
        // },
        // {
        //   key: 'is_invoiceable',
        //   label: this.$t('isInvoiced'),
        // },
        {
          key: 'payment_attachment',
          label: this.$t('file'),
        },
      ],
      paymentToDeleted: {},
    }
  },

  methods: {
    ...mapActions('quotes', ['deleteQuotePayment', 'getQuotePayments']),

    openDeleteModal(item) {
      this.paymentToDeleted = item
      this.$bvModal.show('confirmDelete')
    },

    async removepayment() {
      await this.deleteQuotePayment({
        quoteId: this.$route.params.quoteId,
        paymentId: this.paymentToDeleted.id,
      })
      await this.getQuotePayments({ quoteId: this.$route.params.quoteId })
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-express {
  background-color: #120070;
}
</style>
