<template>
  <b-img
    :height="height"
    :src="require('@/assets/images/yoconstruyo/logo-color.png')"
  />
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default() {
        return '45'
      },
    },
  },
}
</script>

<style></style>
