<template>
  <div>
    <b-card>
      <!-- Button: See uploaded document -->
      <b-button
        variant="outline-secondary"
        class="mb-75"
        block
        @click="printInvoice"
      >
        {{ $t('print') }}
      </b-button>

      <b-button
        :to="{
          name: 'uploadRemission',
          params: { purchaseOrderId: purchaseOrder.id },
        }"
        variant="outline-success"
        class="mb-75"
        block
      >
        {{ $t('remission') }}
      </b-button>
      <b-button
        :to="{
          name: 'purchaseOrders',
          params: { quoteId: $route.params.quoteId },
        }"
        variant="outline-success"
        class="mb-75"
        block
      >
        {{ $t('invoice2') }}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default {
  components: {},
  props: {
    quote: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup(_, { root }) {
    const store = root.$store
    const printInvoice = () => {
      window.print()
    }
    const purchaseOrder = computed(
      () => store.state.purchaseOrders.purchaseOrder,
    )

    function approvePayment() {
      store
        .dispatch('quotes/approvePayment', {
          quoteId: router.currentRoute.params.quoteId,
        })
        .then(() => {
          root.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('paymentApproved'),
              icon: 'CreditCardIcon',
              variant: 'success',
            },
          })
        })
    }

    function rejectPayment() {
      store
        .dispatch('quotes/rejectPayment', {
          quoteId: router.currentRoute.params.quoteId,
        })
        .then(() => {
          root.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('paymentRejected'),
              icon: 'CreditCardIcon',
              variant: 'warning',
            },
          })
        })
    }

    return {
      printInvoice,
      approvePayment,
      rejectPayment,
      purchaseOrder,
    }
  },
}
</script>

<style></style>
