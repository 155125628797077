<template>
  <b-modal
    :id="id"
    size="lg"
    @hidden="resetData"
  >
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h3 class="font-weight-bolder mb-0">
        {{ $t('payments') }}
      </h3>
      <!-- <b-button
        size="sm"
        variant="primary"
        @click="createPayment = true"
      >
        {{ $t('create', { type: $t('payment') }) }}
      </b-button> -->
    </div>
    <div v-if="createPayment">
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent="uploadPayment()">
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                id="amount-group"
                label="Amount"
                label-for="amount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  rules="required"
                >
                  <b-form-input
                    id="amount"
                    v-model="payment.amount"
                    type="number"
                    required
                    step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                id="file-group"
                label="File"
                label-for="file"
              >
                <validation-provider
                  #default="{ errors }"
                  name="file"
                  rules="required"
                >
                  <b-form-file
                    id="file"
                    v-model="file"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mb-2 d-flex justify-content-end">
            <b-button
              size="sm"
              variant="danger"
              class="mr-1"
              @click="resetData"
            >
              {{ $t('cancel') }}
            </b-button>
            <b-button
              size="sm"
              variant="primary"
              type="submit"
            >
              {{ $t('create', { type: $t('payment') }) }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>
    <PaymentsTable :payments="quotePayments" />
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import PaymentsTable from '@/components/payments/PaymentsTable.vue'

export default {
  components: {
    PaymentsTable,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: () => 'confirmModal',
    },
    variant: {
      type: String,
      default() {
        return 'primary'
      },
    },
  },
  data() {
    return {
      createPayment: false,
      file: null,
      payment: {
        amount: 0,
        payment_attachment: {
          data: null,
        },
      },
      required,
    }
  },
  computed: {
    ...mapGetters('quotes', ['quotePayments']),
  },
  methods: {
    ...mapActions('quotes', ['uploadQuotePayment', 'getQuotePayments']),
    async uploadPayment() {
      const isValid = await this.$refs.simpleRules.validate()
      if (!isValid) return
      const file = await this.$getBase64(this.file)
      this.payment.payment_attachment.data = file
      await this.uploadQuotePayment({
        quoteId: this.$route.params.quoteId,
        payment: this.payment,
      })
      await this.getQuotePayments({ quoteId: this.$route.params.quoteId })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('filesSaved'),
          icon: 'SaveIcon',
          variant: 'success',
        },
      })
    },

    resetData() {
      this.createPayment = false
      this.file = null
      this.payment = {
        amount: 0,
        payment_attachment: {
          data: null,
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
