<template>
  <div>
    <b-card>
      <b-button
        variant="info"
        class="mb-75"
        block
        @click="
          $router.push({
            name: 'clientQuoteMetadata',
            params: { id: quote.id },
          })
        "
      >
        <feather-icon
          icon="EyeIcon"
          class="mr-1"
        />
        {{ $t('breakdown') }}
      </b-button>
      <!-- Button: See uploaded document -->
      <b-button
        variant="outline-secondary"
        class="mb-75"
        block
        @click="printInvoice"
      >
        {{ $t('print') }}
      </b-button>

      <!-- <b-button
        :to="{ name: 'clientQuote' }"
        variant="outline-secondary"
        class="mb-75"
        block
      >
        {{ $t('edit') }}
      </b-button> -->

      <b-button
        v-if="
          quote.payment_attachment != '/default.jpg' &&
            quote.payment_attachment != null
        "
        variant="outline-primary"
        class="mb-75"
        block
        target="_blank"
        :href="quote.payment_attachment"
      >
        {{ $t('invoice') }}
      </b-button>
      <!-- OLD PAYMENT VIEW -->
      <!-- <b-button
        :to="{
          name: 'bankAccountData',
          params: { quoteId: $route.params.quoteId },
        }"
        variant="success"
        class="mb-75"
        block
      >
        {{ $t('payment') }}
      </b-button> -->
      <!-- NEW PAYMENT IN MODAL -->
      <UploadPayment />

      <!-- Button: Print -->
      <div class="mt-2">
        <b-button
          v-if="quote.payment_status === 'awaiting_payment_approval'"
          v-b-modal.confirmReject
          variant="outline-danger"
          class="mb-75"
          block
        >
          {{ $t('reject') }}
        </b-button>

        <!-- Button: Add Payment -->
        <b-button
          v-if="quote.payment_status === 'awaiting_payment_approval'"
          v-b-modal.confirmApprove
          variant="outline-success"
          class="mb-75"
          block
        >
          {{ $t('approve') }}
        </b-button>
      </div>
    </b-card>
    <confirmation-modal
      v-if="quote.payment_status === 'awaiting_payment_approval'"
      :id="'confirmApprove'"
      :variant="'success'"
      @confirm="approvePayment"
    >
      <template v-slot:content>
        {{ $t('approvePaymentConfirmation') }}
      </template>
    </confirmation-modal>

    <confirmation-modal
      v-if="quote.payment_status === 'awaiting_payment_approval'"
      :id="'confirmReject'"
      :variant="'danger'"
      @confirm="rejectPayment"
    >
      <template v-slot:content>
        {{ $t('rejectPaymentConfirmation') }}
      </template>
    </confirmation-modal>
    <PaymentsModal id="payments-modal" />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import PaymentsModal from '@/components/payments/PaymentsModal.vue'
import router from '@/router'
import UploadPayment from '@/components/payments/UploadPayment.vue'

export default {
  components: {
    ConfirmationModal,
    PaymentsModal,
    UploadPayment,
  },
  props: {
    quote: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup(_, { root }) {
    const store = root.$store
    const payments = computed(() => store.getters['quotes/quotePayments'])
    const printInvoice = () => {
      window.print()
    }
    const apiUrl = computed(() => store.state.apiUrl)

    function approvePayment() {
      store
        .dispatch('quotes/approvePayment', {
          quoteId: router.currentRoute.params.quoteId,
        })
        .then(() => {
          root.$toast({
            component: ToastificationContent,
            props: {
              title: root.$t('paymentApproved'),
              icon: 'CreditCardIcon',
              variant: 'success',
            },
          })
        })
    }

    function rejectPayment() {
      store
        .dispatch('quotes/rejectPayment', {
          quoteId: router.currentRoute.params.quoteId,
        })
        .then(() => {
          root.$toast({
            component: ToastificationContent,
            props: {
              title: root.$t('paymentRejected'),
              icon: 'CreditCardIcon',
              variant: 'warning',
            },
          })
        })
    }

    return {
      printInvoice,
      apiUrl,
      approvePayment,
      rejectPayment,
      payments,
    }
  },
}
</script>

<style></style>
