<template>
  <div>
    <b-button
      variant="success"
      class="mb-75"
      block
      @click="openFileInput"
    >
      {{ $t('addPayment') }}
    </b-button>
    <div
      v-if="payments.length >= 1"
      class="d-flex align-items-center justify-content-between"
    >
      <b-button
        variant="success"
        class=""
        block
        @click="handleSeeReciepts"
      >
        {{ payments.length === 1 ? $t('seeReceipt') : $t('seeReceipts') }}
      </b-button>
      <b-button
        v-if="payments.length === 1"
        variant="outline-danger"
        class="d-flex custome-button ml-1"
        @click="removePayment"
      >
        <feather-icon
          icon="TrashIcon"
          class=""
        />
      </b-button>
    </div>
    <b-form-file
      id="paymentFile"
      v-model="paymentFile"
      style="display: none"
      @input="uploadPayment($event)"
    />
  </div>
</template>

<script>
import { computed, getCurrentInstance, ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {},
  props: {
    quote: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup(_, { root }) {
    const store = root.$store
    const payments = computed(() => store.getters['quotes/quotePayments'])

    const route = root.$route

    const vm = getCurrentInstance().proxy

    const paymentFile = ref(null)

    function openFileInput() {
      // paymentFile.value.$el.children[0].click()
      document.getElementById('paymentFile').click()
    }

    const handleSeeReciepts = () => {
      if (payments.value.length > 1) {
        vm.$bvModal.show('payments-modal')
      } else {
        const paymentUrl = payments.value[0].payment_attachment
        window.open(paymentUrl, '_blank')
      }
    }

    const payment = ref({
      amount: 0,
      payment_attachment: {
        data: null,
      },
    })

    const uploadPayment = async () => {
      const file = await root.$getBase64(paymentFile.value)
      payment.value.payment_attachment.data = file
      await store.dispatch('quotes/uploadQuotePayment', {
        quoteId: route.params.quoteId,
        payment: payment.value,
      })
      await store.dispatch('quotes/getQuotePayments', {
        quoteId: route.params.quoteId,
      })
      root.$toast({
        component: ToastificationContent,
        props: {
          title: root.$t('filesSaved'),
          icon: 'SaveIcon',
          variant: 'success',
        },
      })
    }

    const removePayment = async () => {
      await store.dispatch('quotes/deleteQuotePayment', {
        quoteId: route.params.quoteId,
        paymentId: payments.value[0].id,
      })
      await store.dispatch('quotes/getQuotePayments', {
        quoteId: route.params.quoteId,
      })
      root.$toast({
        component: ToastificationContent,
        props: {
          title: root.$t('fileDelted'),
          icon: 'SaveIcon',
          variant: 'success',
        },
      })
    }

    return {
      payments,
      handleSeeReciepts,
      openFileInput,
      paymentFile,
      uploadPayment,
      removePayment,
    }
  },
}
</script>

<style></style>
